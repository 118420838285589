<template>
  <v-col cols="12" class="pa-12">
    <v-alert type="info" class="text-center">
      <v-icon x-large class="mr-4">mdi-link-off</v-icon>
      {{ $t("not-found-msg", { url: this.$route.path }) }}
    </v-alert>
  </v-col>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<i18n>
{
  "en": {
    "not-found-msg": "We are sorry, but we couldn't find {url}."
  },
  "de": {
    "not-found-msg": "Tut uns leid, aber wir konnten {url} nicht finden."
  }
}
</i18n>
